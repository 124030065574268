
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import AtomAvatar, { AvatarSize } from '@/components/atoms/AtomAvatar.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import AtomAvatarList from '@/components/atoms/AtomAvatarList.vue';
import AtomAvatarListItem from '@/components/atoms/AtomAvatarListItem.vue';
import AtomActionSelector from '@/components/atoms/AtomActionSelector.vue';
import AtomCircularButton from '@/components/atoms/AtomCircularButton.vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomBadge, { BadgeVariant } from '@/components/atoms/AtomBadge.vue';
import MoleculeCard from '@/components/molecules/cards/MoleculeCard.vue';
import MoleculeModal from '@/components/molecules/modal/MoleculeModal.vue';
import MoleculeDropdown from '@/components/molecules/dropdown/MoleculeDropdown.vue';
import OrganismProfileEdit from '@/components/organisms/profile/OrganismProfileEdit.vue';
import OrganismPhoneValidation from '@/components/organisms/phone-validation/OrganismPhoneValidation.vue';
import { unfollow } from '@/api/community/communityApi';
import { toast, ToastVariants } from '@/components/atoms/AtomToast';

enum CommunityTabs {
  FOLLOWERS = 'FOLLOWERS',
  FOLLOWING = 'FOLLOWING',
}

export default Vue.extend({
  components: {
    AtomAvatar,
    AtomSvgIcon,
    AtomButton,
    AtomBadge,
    AtomActionSelector,
    AtomAvatarListItem,
    AtomAvatarList,
    AtomCircularButton,
    MoleculeCard,
    MoleculeModal,
    MoleculeDropdown,
    OrganismProfileEdit,
    OrganismPhoneValidation,
  },
  name: 'OrganismProfileHeader',
  props: {
    name: {
      type: String,
      required: true,
    },
    points: {
      type: Number,
      default: 0,
    },
    co2: {
      type: Number,
      default: 0,
    },
    distance: {
      type: Number,
      default: 0,
    },
    phone: {
      type: String,
      required: true,
    },
    isPhoneVerified: {
      type: Boolean,
      required: false,
    },
    email: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions('user', [
      'setFollowingMembers',
      'removeFollowerById',
    ]),
    showCommunity(communityTab: CommunityTabs) {
      this.activeCommunityTab = communityTab;
      this.$bvModal.show('community-members');
    },
    async unfollowUser(userId: string, name: string) {
      const { status } = await unfollow(this.getUserId, userId);
      if (status === 201) {
        this.removeFollowerById(userId);
        toast(this.$bvToast, this.$t('profile.messages.unfollowUser', { name }).toString(), ToastVariants.SUCCESS);
      } else {
        toast(this.$bvToast, this.$t('profile.messages.error').toString(), ToastVariants.DANGER);
      }
    },
  },
  data: () => ({
    AvatarSize,
    BadgeVariant,
    ButtonVariant,
    CommunityTabs,
    isFollowed: false,
    activeCommunityTab: CommunityTabs.FOLLOWING,
  }),
  computed: {
    ...mapGetters('user', {
      decodedImage: 'decodedImage',
      getUserId: 'getUserId',
      following: 'following',
      followers: 'followers',
    }),
  },
});
