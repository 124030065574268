
import Vue from 'vue';
import i18n from '@/i18n';
import TemplateProfile from '@/components/templates/profile/TemplateProfile.vue';

export default Vue.extend({
  name: 'Profile',
  components: { TemplateProfile },
  metaInfo: {
    title: ` - ${i18n.t('tabs.profile')}`,
  },
});
