
import Vue from 'vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import OrganismProfileExtra from '@/components/organisms/profile/OrganismProfileExtra.vue';

export default Vue.extend({
  name: 'OrganismProfileStats',
  components: {
    AtomSvgIcon,
    OrganismProfileExtra,
    AtomText,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    subheading: {
      type: String,
      required: true,
    },
  },
});
