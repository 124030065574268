
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import i18n from '@/i18n';
import AtomAddImage from '@/components/atoms/AtomAddImage.vue';
import AtomDropdownSelect from '@/components/atoms/AtomDropdownSelect.vue';
import AtomBadge, { BadgeVariant } from '@/components/atoms/AtomBadge.vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomTextInput from '@/components/atoms/AtomInput/AtomTextInput.vue';
import MoleculeModal from '@/components/molecules/modal/MoleculeModal.vue';
import RolesEnum from '@/enums/rides/RolesEnum';
import FeatureEnum from '@/enums/settings/FeatureEnum';
import { hasFeature } from '@/services/feature/FeatureService';
import { updateUser, deleteUserImage } from '@/api/user/userApi';
import { toast, ToastVariants } from '@/components/atoms/AtomToast';
import { NAME_REGEX } from '@/common/config';

export default Vue.extend({
  name: 'OrganismProfileEdit',
  components: {
    AtomAddImage,
    AtomButton,
    AtomBadge,
    AtomDropdownSelect,
    AtomTextInput,
    MoleculeModal,
  },
  data() {
    return {
      userGender: null,
      userFirstName: '',
      userLastName: '',
      userDisplayName: '',
      userUnitOfLengthLocale: '',
      userAvatar: null as string | null,
      phone: undefined as string | undefined,
      renderPhoneModal: true,
      RolesEnum,
      FeatureEnum,
      ButtonVariant,
      BadgeVariant,
      validFields: {
        firstName: true,
        lastName: true,
        nickName: true,
      },
      nameRequirements: [{
        validator: (input: string) => NAME_REGEX.test(input) && (input.length >= 2 && input.length <= 50),
        showOnValid: false,
        description: i18n.t('register.namesRequirements').toString(),
        formatDescriptionAsHTML: true,
      }],
      nickNameRequirements: [{
        validator: (input: string) => input.length >= 2 && input.length <= 50,
        showOnValid: false,
        description: i18n.t('register.nicknameRequirement').toString(),
      }],
      genderOptions: [
        { text: 'dictionary.female', value: 'female', disabled: false },
        { text: 'dictionary.male', value: 'male', disabled: false },
        { text: 'dictionary.divers', value: 'diverse', disabled: false },
        { text: 'dictionary.unspecified', value: 'unspecified' },
      ],
      unitOptions: [
        { text: 'dictionary.kilometer', value: 'de_DE', disabled: false },
        { text: 'dictionary.mile', value: 'en_US', disabled: false },
      ],
      isLoading: false,
    };
  },
  methods: {
    ...mapActions('rideIntent', ['setMeetingPoint']),
    ...mapActions('user', ['fetchUser', 'setUserDecodedImage']),
    hasFeature,
    async finish() {
      this.isLoading = true;
      const { status } = await updateUser({
        userId: this.getUserId,
        firstName: this.userFirstName.trim(),
        lastName: this.userLastName.trim(),
        displayName: this.userDisplayName.trim(),
        unitOfLengthLocale: this.userUnitOfLengthLocale,
        image: this.userAvatar || null,
        gender: this.userGender,
        rideSettings: {
          maxDetourMinutes: this.rideSettings.maxDetourMinutes,
          minSharedDurationPercentage: this.rideSettings.minSharedDurationPercentage,
          role: this.rideSettings.role,
        },
        sms: !this.phone ? null : {
          detail: this.phone,
          usageTypes: ['ContactVerification', 'Help', 'Unsubscribe', 'RideCreatedUpdatedCancelled'], // TODO: confirm this if this Array contains anything needed
        },
      });
      this.isLoading = false;

      if (status === 200) {
        this.$bvModal.hide('tg-modal--edit-profile');
        this.fetchUser(this.getUserId);
        toast(this.$bvToast, this.$t('profile.messages.success').toString());
      } else {
        toast(this.$bvToast, this.$t('profile.messages.error').toString(), ToastVariants.DANGER);
      }
    },
    setClippedImage(image: string) {
      this.userAvatar = image;
    },
    onCropCancel() {
      this.userAvatar = this.decodedImage;
    },
    async deleteUserImage() {
      if (this.getUserId) {
        const { status } = await deleteUserImage(this.getUserId);
        if (status === 200) {
          this.fetchUser(this.getUserId);
          this.userAvatar = this.decodedImage;
          toast(this.$bvToast, this.$t('profile.messages.success').toString());
        } else {
          toast(this.$bvToast, this.$t('profile.messages.error').toString(), ToastVariants.DANGER);
        }
      }
    },
    resetState() {
      this.userGender = this.gender;
      this.userFirstName = this.firstName;
      this.userLastName = this.lastName;
      this.userDisplayName = this.displayName;
      this.userUnitOfLengthLocale = this.unitOfLengthLocale;
      this.phone = this.phoneNumber;
      this.userAvatar = this.decodedImage;
    },
    openPhoneValidation() {
      this.$bvModal.show('tg-modal--phone-validation');
    },
    openSiteAssignment() {
      this.$bvModal.show('tg-modal--user-site-assignment');
    },
  },
  computed: {
    ...mapGetters('rideIntent', ['getStartPoint', 'getMeetingPoint']),
    ...mapGetters('user', [
      'features',
      'getUserId',
      'isPhoneVerified',
      'firstName',
      'lastName',
      'displayName',
      'gender',
      'unitOfLengthLocale',
      'homePOI',
      'phoneNumber',
      'rideSettings',
      'decodedImage',
      'siteName',
    ]),
    selectedGender(): any {
      const option = this.genderOptions.find((o) => o.value === this.gender);
      return option ? { ...option, text: this.$t(option.text) } : null;
    },
    selectedUnit(): any {
      const option = this.unitOptions.find((o) => o.value === this.unitOfLengthLocale);
      return option ? { ...option, text: this.$t(option.text) } : null;
    },
    areRequiredFieldsCompleted(): boolean {
      return !Object.values(this.validFields).includes(false);
    },
  },
  watch: {
    decodedImage() {
      this.userAvatar = this.decodedImage;
    },
    phoneNumber(phone) {
      this.phone = phone;
    },
  },
});
