
import Vue from 'vue';
import VueLottiePlayer from 'vue-lottie-player';
import { mapActions, mapGetters } from 'vuex';
import AtomCard from '@/components/atoms/AtomCard.vue';
import AtomAvatar, { AvatarSize } from '@/components/atoms/AtomAvatar.vue';
import AtomActionSelector from '@/components/atoms/AtomActionSelector.vue';
import AtomAvatarList from '@/components/atoms/AtomAvatarList.vue';
import AtomAvatarListItem from '@/components/atoms/AtomAvatarListItem.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomBadge, { BadgeVariant } from '@/components/atoms/AtomBadge.vue';
import MoleculeModal, { ModalSize } from '@/components/molecules/modal/MoleculeModal.vue';
import OrganismPageSubHeader from '@/components/organisms/page-header/OrganismPageSubHeader.vue';
import OrganismProfileHeader from '@/components/organisms/profile/OrganismProfileHeader.vue';
import OrganismSiteAssignmentModal from '@/components/organisms/site-assignment/OrganismSiteAssignmentModal.vue';
import OrganismProfileStats from '@/components/organisms/profile/OrganismProfileStats.vue';

import FeatureEnum from '@/enums/settings/FeatureEnum';
import GamificationTypeEnum from '@/enums/gamification/GamificationTypeEnum';
import LeaderBoardModel from '@/models/gamification/LeaderBoardModel';
import SiteModel from '@/models/site/SiteModel';

import { hasFeature } from '@/services/feature/FeatureService';
import { getBrandedCompanyLogo, getBrandingById } from '@/services/branding/BrandingService';
import isEnvReady, { Features } from '@/services/utilities/EnvFeatureService';
import { fetchImage } from '@/api/user/userApi';
import { getUserRating } from '@/api/ratings/ratingsApi';
import { setOptIn, getCategorizedLeaderBoard, getMyCategorizedLeaderBoard, removeOptIn } from '@/api/gamification/gamificationApi';
import { getSites } from '@/api/site/siteApi';

enum LeaderBoardCategories {
  LAST_MONTH = 'lastMonth',
  THIS_MONTH = 'thisMonth',
  ALL_TIME = 'allTime',
}

enum RatingErrorCodes {
  NOT_ENOUGH_RATINGS = 'NOT_ENOUGH_RATINGS',
}

export default Vue.extend({
  name: 'TemplateProfile',
  data: () => ({
    FeatureEnum,
    Features,
    LeaderBoardCategories,
    ButtonVariant,
    BadgeVariant,
    ModalSize,
    AvatarSize,
    LEADER_BOARD_OPT_IN_MODAL: 'LEADER_BOARD_OPT_IN',
    LEADER_BOARD_MODAL: 'LEADER_BOARD_MODAL',
    RATING_DETAILS_MODAL: 'RATING_DETAILS_MODAL',
    lastMonthLeaderBoard: [] as LeaderBoardModel[],
    thisMonthLeaderBoard: [] as LeaderBoardModel[],
    allTimeLeaderBoard: [] as LeaderBoardModel[],
    activeLeaderBoardTab: LeaderBoardCategories.THIS_MONTH,
    sites: [] as SiteModel[],
    isOverlayOpened: false,
    rating: 0,
    ratingAnnotation: '',
    ratingErrorCode: '' as RatingErrorCodes,
    RatingErrorCodes,
    isLoading: false,
    isLoadingDecline: false,
  }),
  components: {
    VueLottiePlayer,
    AtomActionSelector,
    AtomAvatar,
    AtomAvatarList,
    AtomAvatarListItem,
    AtomCard,
    AtomSvgIcon,
    AtomText,
    AtomButton,
    AtomBadge,
    MoleculeModal,
    OrganismPageSubHeader,
    OrganismProfileHeader,
    OrganismProfileStats,
    OrganismSiteAssignmentModal,
  },
  computed: {
    ...mapGetters('user', [
      'getCidaasUserData',
      'phoneNumber',
      'email',
      'fullName',
      'followers',
      'following',
      'isPhoneVerified',
      'gamification',
      'siteCompanyName',
      'siteName',
      'features',
      'site',
      'hasAcceptedLeaderBoard',
      'hasAcceptedExperience',
      'hasMadeGamingDecision',
      'hasUserLoaded',
      'getUserId',
      'getUserAvatar',
    ]),
    getCompanyLogo() {
      const company = getBrandingById(this.site?.companyId);
      return company ? getBrandedCompanyLogo(company) : '';
    },
    hasLeaderBoardAccessEnabled(): boolean {
      return (
        hasFeature(this.features, FeatureEnum.GAMIFICATION_UI_LEADERBOARD)
        && !hasFeature(this.features, FeatureEnum.GAMIFICATIONOPTOUT_EXPERIENCE)
      );
    },
  },
  methods: {
    ...mapActions('user', ['setGamificationStatus', 'setAvatars']),
    hasFeature,
    isEnvReady,
    fetchImage,
    async leaderBoardOptIn(status: boolean): Promise<void> {
      if (status) {
        this.isLoading = true;
        await setOptIn(GamificationTypeEnum.EXPERIENCE);
        const { data } = await setOptIn(GamificationTypeEnum.LEADER_BOARD);

        if (data) this.setGamificationStatus(data.gamification.status);
        this.fetchLeaderBoard();
        this.isLoading = false;
      } else {
        this.isLoadingDecline = true;
        await removeOptIn(GamificationTypeEnum.EXPERIENCE);
        const { data } = await removeOptIn(GamificationTypeEnum.LEADER_BOARD);

        if (data) this.setGamificationStatus(data.gamification.status);
        this.isLoadingDecline = false;
      }

      this.$bvModal.hide(this.LEADER_BOARD_OPT_IN_MODAL);
    },
    getLeaderBoard(category: LeaderBoardCategories): LeaderBoardModel[] {
      switch (category) {
        case LeaderBoardCategories.LAST_MONTH:
          return this.lastMonthLeaderBoard; // .filter((el) => el.pos > 3);
        case LeaderBoardCategories.ALL_TIME:
          return this.allTimeLeaderBoard;
        case LeaderBoardCategories.THIS_MONTH:
        default:
          return this.thisMonthLeaderBoard;
      }
    },
    getLeaderBoardPreview(list: LeaderBoardModel[], userId: string): LeaderBoardModel[] {
      const userRank = list.find((el) => el.userId === userId)?.pos || NaN;
      if (userRank <= 3) {
        const leaderBoardList = list.slice(0, 4);
        return leaderBoardList.length > 4
          ? [...leaderBoardList, { ...list[0], pos: NaN, nickname: 'Placeholder', userId: 'placeholder' }]
          : leaderBoardList;
      }

      return list.reduce((acc, el, index) => {
        if (index <= 2) acc.push(el);
        if (el.userId === userId) {
          if (el.pos > 4) acc.push({ ...el, pos: NaN, nickname: 'Placeholder', userId: 'placeholder' });
          acc.push(el);
        }

        return acc;
      }, [] as LeaderBoardModel[]);
    },
    isPedestalMode(activeLeaderBoardTab: LeaderBoardCategories, rank: number): boolean {
      return activeLeaderBoardTab !== LeaderBoardCategories.THIS_MONTH && rank <= 3;
    },
    mergeLeaderBoard(generalLeaderBoard: LeaderBoardModel[], myLeaderBoard: LeaderBoardModel[]): LeaderBoardModel[] {
      return generalLeaderBoard
        .concat(myLeaderBoard.filter((ml) => !generalLeaderBoard.some((gl) => ml.userId === gl.userId)))
        .sort((a, b) => a.pos - b.pos);
    },
    async fetchLeaderBoard(): Promise<void> {
      if (this.hasLeaderBoardAccessEnabled && this.hasAcceptedLeaderBoard) {
        const { data: leaderBoard } = await getCategorizedLeaderBoard();
        const { data: myLeaderBoard } = await getMyCategorizedLeaderBoard(this.getUserId);
        if (leaderBoard && myLeaderBoard) {
          this.lastMonthLeaderBoard = this.mergeLeaderBoard(leaderBoard.lastMonth, myLeaderBoard.lastMonth);
          this.thisMonthLeaderBoard = this.mergeLeaderBoard(leaderBoard.thisMonth, myLeaderBoard.thisMonth);
          this.allTimeLeaderBoard = this.mergeLeaderBoard(leaderBoard.allTime, myLeaderBoard.allTime);
        }
      }
      this.cacheAvatars(
        [...this.lastMonthLeaderBoard, ...this.thisMonthLeaderBoard, ...this.allTimeLeaderBoard]
          .reduce((acc, { imageURL, userId }) => {
            if (!acc.find((u) => u.userId === userId) && !this.getUserAvatar(userId)) {
              acc.push({ imageURL, userId });
            }

            return acc;
          }, [] as Pick<LeaderBoardModel, 'imageURL' | 'userId'>[]),
      );
    },
    async cacheAvatars(userAvatarUrls: Pick<LeaderBoardModel, 'imageURL' | 'userId'>[]) {
      const avatarPromises = userAvatarUrls.map(async (avatar) => ({
        imageURL: await fetchImage(avatar.imageURL),
        userId: avatar.userId,
      }));

      const resolvedPromises = await Promise.all(avatarPromises);
      const avatarList = {};
      resolvedPromises.forEach((el) => { avatarList[el.userId] = el.imageURL; });

      this.setAvatars(avatarList);
    },
    getRatingSubheadingKey(rating: number, annotation: string): string {
      if (rating < 3) return `ratings.subHeading${rating}`;
      return `ratings.subHeading${rating}_${annotation}`;
    },
    getRatingEmojiIcon(rating: number) {
      switch (rating) {
        case 1:
          return 'icons/icon-emoji-sad.svg';
        case 2:
          return 'icons/icon-emoji-neutral.svg';
        case 3:
          return 'icons/icon-emoji-happy.svg';
        default:
          return '';
      }
    },
    async fetchSites() {
      const { data = [] } = await getSites(this.getUserId);
      this.sites = data;
    },
  },
  async mounted() {
    this.fetchLeaderBoard();
    this.fetchSites();
    const res = await getUserRating(this.getUserId);

    this.rating = res.data?.rating || 0;
    this.ratingAnnotation = res.data?.mostRatedAnnotation || '';
    this.ratingErrorCode = res.message as RatingErrorCodes || '';
  },
  watch: {
    hasAcceptedLeaderBoard() {
      this.fetchLeaderBoard();
    },
  },
});
